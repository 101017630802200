:root {
    --header-height: 76px;
    --footer-height: 150px;
}

footer {
    height: var(--footer-height);
    position: 'absolute';
    width: 100vw;
    height: var(var(--footer-height));
    left: 0;

}

.remainder-height {
    min-height: calc(100vh - var(--header-height) - var(--footer-height));
}

.hidden {
    display: none;
}

.profile-img {
    height: 20px;
}

.logo-header {
    height: 50px;
}