$green:   #0ca666 !default;
$cyan:    #0dcaf0 !default;
$yellow:  #ffc107 !default;
$red:     #dc3545 !default;
$gray-100: #f9fbfa !default;
$gray-600: #6d7e77 !default;
$gray-900: #222a27 !default;

$primary:       $green !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-900 !default;


// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

.btn-primary {
  color: white
}

.btn-primary:hover {
  color: white
}

.btn-primary:disabled {
  color: white
}

.hover{
  background-color: white;
}
.hover:hover{
  background-color: $light;
}