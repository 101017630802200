.alerts-container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 3000;
    /* Adjust the z-index value as needed */
}

.alert {
    margin-right: 16px;
}